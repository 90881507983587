import React, { useMemo, createContext, useEffect } from "react";
import cx from "classnames";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Text } from "@geist-ui/core";
import { ChevronDown } from "@geist-ui/icons";

import { useGetTopics } from "../../api/useGetTopics";
import { PopoverMenu } from "../popup";
import {
  ESessionStorage,
  saveToSessionStorage,
} from "../../assets/storage/sessionStorage";
import { ENavigate } from "../sidebar/sidebar.types";

import styles from "./styles.module.scss";
import { useSamples } from "../../api/useSamples";
import { useFeatureFlag } from "configcat-react";
import { useAuth0 } from "@auth0/auth0-react";
import { GeistBadge } from "../atoms/geistBadge";

const topicsRoutes = [
  ENavigate.ACTIVATION,
  ENavigate.ASSESSMENT,
  ENavigate.KNOWLEDGE,
];
const samplingRoutes = [ENavigate.SAMPLING];

export const TopbarContext = createContext<{
  path: string;
  setPath: (p: string) => void;
}>({ path: "", setPath: () => {} });

export const Topbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("t") ?? "";

  const { value } = useFeatureFlag("integrations", "nothing:nothing", {
    identifier: token ?? "",
    email: "test@quack.ai",
    custom: {
      t: token,
    },
  });

  const { user } = useAuth0();
  const { value: betaFeatures } = useFeatureFlag(
    "betaFeatures",
    "nothing:nothing",
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const tenant = value.split(":")[0];

  const pageName = useMemo(() => {
    const pathName = location.pathname.split("/")[1].replaceAll("-", " ");
    if (pathName === "home") return "";
    if (pathName === "welcome")
      return `Hi ${tenant} team  👋, we're glad to see you!`;
    if (pathName === "kb_eval") {
      return `Hi 👋, we're happy to see you!`;
    }
    return pathName.charAt(0).toUpperCase() + pathName.slice(1);
  }, [location, tenant]);

  const { data, isLoading: loadTopics } = useGetTopics();
  const { data: samplesData, isLoading: loaderSample } = useSamples();

  const { topicId, samplingId } = useMemo(() => {
    const pathSegments = location.pathname.split("/");
    const route = pathSegments[1];
    if (samplingRoutes.includes(route as ENavigate)) {
      return { topicId: null, samplingId: pathSegments[2] };
    }
    if (
      topicsRoutes.includes(route as ENavigate) &&
      !pathSegments.includes("wizard")
    ) {
      return { topicId: pathSegments[2], samplingId: null };
    }

    return { topicId: null, samplingId: null };
  }, [location.pathname]);

  const newData = useMemo(() => {
    const pathSegments = location.pathname.split("/");
    const route = pathSegments[1];
    if (route === ENavigate.ACTIVATION && Array.isArray(data)) {
      return data?.filter(
        (topic: any) =>
          topic?.commonQuestion?.length > 0 &&
          topic?.commonQuestion.some(
            (question: any) => question.readyAutoResponse
          )
      );
    }
    return data;
  }, [data, location.pathname]);

  const selectedTopic = useMemo(() => {
    if (topicId && newData) {
      const topic = newData?.find((t: any) => +t?.id === +topicId);
      return topic;
    } else {
      return null;
    }
  }, [newData, topicId]);

  const selectedSample = useMemo(() => {
    if (samplingId && samplesData) {
      const sample = samplesData?.find(
        (sample: any) => +sample.id === +samplingId
      );
      return sample;
    } else {
      return null;
    }
  }, [samplesData, samplingId]);

  useEffect(() => {
    if (newData?.length) {
      const firstReadyTopic = newData.find((topic: any) =>
        topic?.commonQuestion?.some(
          (question: any) => question.readyAutoResponse
        )
      );

      if (firstReadyTopic) {
        saveToSessionStorage(ESessionStorage.FIRST_TOPIC, firstReadyTopic.id);
      }
    }
  }, [newData]);

  const beta = useMemo(() => {
    const pathSegments = location.pathname.split("/");
    const route = pathSegments[1];
    let additionalRoute = "";
    if (pathSegments.length > 2) {
      additionalRoute = pathSegments[2];
    }
    return (betaFeatures && betaFeatures.includes(route)) || additionalRoute
      ? betaFeatures.includes(additionalRoute)
      : false;
  }, [betaFeatures, location.pathname]);

  return (
    <div className={styles.topbarContainer}>
      <Text h3 className={styles.topbarText}>
        {pageName}
        {beta && <GeistBadge bigger>Beta</GeistBadge>}
      </Text>
      {topicId && (
        <>
          <div style={{ display: "flex", gap: "8px" }}>
            <Text h3 className={styles.topbarText}>
              /
            </Text>
            <PopoverMenu
              options={newData?.map((topic: { id: number; topic: string }) => {
                return {
                  key: topic.id,
                  label: topic.topic,
                };
              })}
              placement="bottom-end"
              handleClick={(t) => {
                navigate(
                  `/${pageName.toLocaleLowerCase().replace(" ", "-")}/${t}`
                );
              }}
              disableDisappearance
            >
              <Text
                h3
                className={cx(styles.topbarText, styles.button, {
                  [styles.loading]: loadTopics || !selectedTopic?.topic,
                })}
              >
                {!loadTopics && selectedTopic?.topic ? (
                  <>
                    {selectedTopic?.topic} <ChevronDown />
                  </>
                ) : (
                  "Loading Loading Loading Loading"
                )}
              </Text>
            </PopoverMenu>
          </div>
        </>
      )}
      {samplingId && (
        <>
          <div style={{ display: "flex", gap: "8px" }}>
            <Text h3 className={styles.topbarText}>
              /
            </Text>
            <Text
              h3
              className={cx(styles.topbarText, styles.button, styles.sampling, {
                [styles.loading]: loaderSample || !selectedSample?.name,
              })}
            >
              {!loaderSample && selectedSample?.name ? (
                <>{selectedSample?.name}</>
              ) : (
                "Loading Loading Loading Loading"
              )}
            </Text>
          </div>
        </>
      )}
    </div>
  );
};
