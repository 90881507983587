import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import cx from "classnames";
import { useAuth0 } from "@auth0/auth0-react";

import "./App.css";
import Login from "./routes/login";
import AccountSetup from "./routes/accountSetup";
import { Sidebar } from "./components/sidebar";
import { InstallSuccess } from "./routes/successfullInstall";
import { Supervision } from "./routes/supervision";
import { Report } from "./routes/report";
import { Assessment } from "./routes/assessment";
import { AssessmentByTopicId } from "./routes/assessmentByTopicId";
import { AssessmentByTicketId } from "./routes/assessmentByTicketId";
import { Topbar, TopbarContext } from "./components/topbar";
import styles from "./styles.module.scss";
import { Activation } from "./routes/activation";
import { ActivationTopic } from "./routes/activationTopic";
import { KnowledgeManagement } from "./routes/knowledgeManagement";
import { KnowledgeByTopic } from "./routes/knowledgeByTopic";
import { ArticleDraft } from "./routes/articleDraft";
import { Analysis } from "./routes/analysis";
import { Sampling } from "./routes/sampling";
import { SamplingById } from "./routes/samplingById";
import { Performance } from "./routes/performance";
import { mixRegisterSuperProps, mixSetUserProps } from "./assets/mixpanel";
import { useMembers } from "./routes/accountSetup/api/useMembers";
import { createToast } from "vercel-toast";
import { VoiceOfCustomer } from "./routes/vocOfCustomer";
import { Loader } from "./components/loader";
import { IntegrationsInvite } from "./routes/integrations-invite";
import { Simulator } from "./routes/simulator";
import { Reports } from "./routes/reports";
import { ArticleWizard } from "./routes/articleWizard";
import { Customers } from "./routes/customers";
import { Customer } from "./routes/customer";
import { Simulators } from "./routes/simulators";
import { SimulatorWrapper } from "./routes/simulators/wrapper";
import ArticlePage from "./routes/articlePage";

const onlyAdminsRoutes = new Map([
  ["/supervision", <Supervision />],
  ["/settings", <AccountSetup />],
  ["/home", <Assessment />],
  ["/assessment/:topicId", <AssessmentByTopicId />],
  ["/assessment/:topicId/:ticketId", <AssessmentByTicketId />],
  ["/activation", <Activation />],
  ["/activation/:topicId", <ActivationTopic />],
  ["/knowledge-management", <KnowledgeManagement />],
  ["/knowledge-management/:topicId", <KnowledgeByTopic />],
  ["/knowledge-management/:topicId/wizard/:articleId", <ArticleWizard />],
  ["/knowledge-management/:topicId/article/:articleId", <ArticlePage />],
  ["/knowledge-management/:topicId/:articleId", <ArticleDraft />],
  ["/assessment", <Analysis />],
  ["/sampling", <Sampling />],
  ["/sampling/:sampleId", <SamplingById />],
  ["/performance", <Performance />],
  ["/voice-of-customer", <VoiceOfCustomer />],
  [
    "/simulator/:simulatorId",
    <SimulatorWrapper>
      <Simulator />
    </SimulatorWrapper>,
  ],
  [
    "/simulator",
    <SimulatorWrapper>
      <Simulators />
    </SimulatorWrapper>,
  ],
  ["/sessions-reports", <Reports />],
  ["/customers", <Customers />],
  ["/customers/:organizationId", <Customer />],
]);

const onlyUsersRoutes = new Map([["/report/:id", <Report />]]);

// for it to work, make sure to update 'unprocted' in the sidebar
const openRoutes = new Map([
  ["/installation-successfull", <InstallSuccess />],
  ["/login", <Login />],
  ["/welcome", <IntegrationsInvite source="feature_flag" />],
  ["/kb_eval", <IntegrationsInvite source="searchparam" />],
]);

const RenderUnauthorized = ({ owner }: { owner: string }) => {
  const [countdown, setCountdown] = useState(10);
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      if (linkRef.current) {
        linkRef.current.click();
      }
    }
  }, [countdown]);

  return (
    <div id="main" className={cx(styles.dark_mode, styles.container)}>
      <div className={styles.secondContainer}>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            paddingTop: "300px",
            gap: "16px",
          }}
        >
          We've moved to a new domain. You'll be redirected in {countdown}{" "}
          seconds.
          <a
            ref={linkRef}
            href={`https://${owner}.quack.cx`}
            style={{ color: "white", textDecoration: "underline" }}
          >
            Go to the new platform.
          </a>
        </div>
      </div>
    </div>
  );
};

function App() {
  const [path, setPath] = useState("");
  const { user, logout, isLoading } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      mixSetUserProps(true, {
        name: user?.name,
        email: user?.email,
        id: user?.sub,
        tenant: user?.owner,
        webapp_user: true,
      });
      mixRegisterSuperProps({
        webapp_event: true,
      });
    }
  }, [user]);

  const { data: membersData, isLoading: membersLoading } = useMembers(
    user?.owner,
    user !== null
  );

  const userDetails = membersData?.listUsers?.items?.find(
    (member: any) => member?.id === `${user?.email}::${user?.owner}`
  );

  const isAdmin = useMemo(
    () => userDetails?.isManager && membersData,
    [userDetails, membersData]
  );

  useEffect(() => {
    const currentPath = location.pathname;
    const isAdminRoute = Array.from(onlyAdminsRoutes.keys()).some(
      (path) =>
        path === currentPath ||
        (path.includes(":") && currentPath.startsWith(path.split(":")[0]))
    );

    if (
      !membersLoading &&
      !isLoading &&
      membersData !== undefined &&
      isAdminRoute &&
      !isAdmin
    ) {
      createToast(`Not Authorized user, Contact your manager`, {
        type: "dark",
        timeout: 3000,
      });
    }
  }, [isAdmin, membersData, location, membersLoading, isLoading]);

  const renderRoute = useCallback(
    (path: string, element: React.ReactNode) => {
      if (openRoutes.has(path)) {
        return <Route key={path} path={path} element={element} />;
      }
      if (onlyAdminsRoutes.has(path)) {
        return (
          <Route
            key={path}
            path={path}
            element={<RenderUnauthorized owner={user?.owner ?? ""} />}
          />
        );
      }
      if (onlyUsersRoutes.has(path)) {
        return <Route key={path} path={path} element={element} />;
      }
      return null;
    },
    [isAdmin, logout]
  );

  return (
    <div id="main" className={cx(styles.dark_mode, styles.container)}>
      {!isLoading && !membersLoading ? (
        <>
          {/* <Sidebar /> */}
          <div className={styles.secondContainer}>
            {/* <Topbar /> */}
            <TopbarContext.Provider value={{ path: path, setPath: setPath }}>
              <Routes>
                {[
                  ...Array.from(onlyAdminsRoutes),
                  ...Array.from(onlyUsersRoutes),
                  ...Array.from(openRoutes),
                ].map(([path, element]) => renderRoute(path, element))}
                <Route path="*" element={<Navigate to="/home" />} />
              </Routes>
            </TopbarContext.Provider>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default App;
