import React, { useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { ArrowLeft, ArrowRight } from "@geist-ui/icons";

import { EBadgeMode, GeistBadge } from "../../../components/atoms/geistBadge";
import { GDashboardTable } from "../../../components/geistTableDashboard";
import { EColumnTypes } from "../../../components/geistTableDashboard/index.types";
import { badgeBinary, badgeOptions } from "../constants";

import styles from "./styles.module.scss";
import { SampleTicket } from "./sampleTicket";
import {
  useMutateScore,
  useSkipTicketScore,
  useTicketsBySample,
} from "../../../api/useSamples";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  TAgent,
  TComments,
  TPanelComments,
  TSampleData,
  TTenantUser,
  TTicketScore,
  TTopic,
} from "../samplingById.types";
import { Button, Textarea } from "@geist-ui/core";
import { Tooltip } from "@geist-ui/core";
import { GeistSelect } from "../../../components/geistSelect";
interface ITicket {
  currTicket: {
    id: string;
    topic: TTopic;
    subject: string;
    description: string;
    agentAssignedId: number;
    comments: TComments[];
    ticketScore: TTicketScore[];
    displayId: string | null;
  };
  agents: TAgent[];
  tenantUser: TTenantUser;
  sample: TSampleData;
}

const reasonsAnswers = [
  {
    question: "UNDERSTAND THE INQUIRY",
    answers: [
      {
        title: "POOR ACTIVE LISTENING",
        description:
          "Failing to attentively listen and understand the client's needs before formulating a response.",
      },
      {
        title: "REPETITIVE QUESTIONS",
        description:
          "Repeatedly asking the same questions, indicating a failure to grasp the information.",
      },
      {
        title: "INCONSISTENT INFORMATION",
        description:
          "Providing conflicting details, showcasing a lack of understanding of the inquiry.",
      },
      {
        title: "LANGUAGE BARRIERS",
        description:
          "Difficulty in understanding due to linguistic differences, impacting inquiry comprehension.",
      },
      {
        title: "MISSED TO ASK CLARIFYING QUESTION",
        description:
          "Overlooking the necessity of seeking additional information for better understanding.",
      },
      {
        title: "RUSHED RESPONSES",
        description:
          "Providing hurried and insufficient replies, lacking consideration for the client's concerns",
      },
    ],
  },
  {
    question: "SHOW EMPATHY",
    answers: [
      {
        title: "LACK OF EMPATHY",
        description:
          "Demonstrating a lack of understanding or sensitivity towards the client's situation.",
      },
      {
        title: "FAILURE TO ACKNOWLEDGE MISTAKE",
        description:
          "Neglecting to admit errors or faults, diminishing the empathetic connection.",
      },
      {
        title: "LONG WAIT TIMES",
        description:
          "Keeping the client waiting for an extended period, displaying a lack of consideration for their time.",
      },
      {
        title: "LACK OF CONFIDENCE",
        description:
          "Projecting uncertainty or doubt in responses, undermining the clients confidence.",
      },
      {
        title: "INABILITY TO ADDRESS URGENCY",
        description:
          "Failing to recognize and appropriately respond to the time-sensitive nature of the inquiry.",
      },
      {
        title: "IMPERSONAL INTERACTIONS",
        description:
          "Lack of personal connection or rapport during interactions, reducing empathy.",
      },
      {
        title: "UNRESPONSIVE",
        description:
          "Failing to promptly respond to client queries or messages, hindering effective communication.",
      },
    ],
  },
  {
    question: "EXPLAIN PLAN OF ACTION",
    answers: [
      {
        title: "NOT GETTING TO THE POINT",
        description:
          "Failed to articulate the process/solution clearly, resulting in back-and-forths.",
      },
      {
        title: "LACK OF CUSTOMER EDUCATION",
        description:
          "Failing to provide informative guidance or assistance, leading to client frustration.",
      },
      {
        title: "INADEQUATE FOLLOW UP",
        description:
          "Not properly monitoring or revisiting the clients concerns after the initial interaction.",
      },
      {
        title: "INACCURATE PROMISES",
        description:
          "Providing assurances that cannot be fulfilled, leading to unmet expectations.",
      },
      {
        title: "MISSED TO SET PROPER EXPECTATIONS",
        description:
          "Overlooking the importance of communicating clear expectations regarding issue resolution.",
      },
      {
        title: "INVALID ESCALATIONS",
        description:
          "Incorrectly elevating the issue to a higher level without proper justification.",
      },
    ],
  },
  {
    question: "RESOLUTION CONFIRMATION",
    answers: [
      {
        title: "NO THOROUGH INVESTIGATION",
        description:
          "Concluding without a comprehensive examination of the clients concerns.",
      },
      {
        title: "LACK OF PRODUCT KNOWLEDGE EXPERTISE",
        description:
          "Demonstrating a deficiency in understanding the product or service being addressed.",
      },
      {
        title: "UNRESOLVED ISSUES",
        description:
          "Not acknowledging situations where the clients problems were not adequately addressed or resolved.",
      },
      {
        title: "FAILED TO CONFIRM RESOLUTION",
        description:
          "Failed to confirm if the solution or answer given addresses their concern",
      },
    ],
  },
];

export const ExpandableListLayout = ({
  idSubjectScore,
}: {
  idSubjectScore: {
    ticketId: string;
    displayId: string | null;
    subject: string;
    score: number;
  }[];
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const [isExpandedBounced, setExpandedBounce] = useState(false);
  const [ticketChose, setTicketChose] = useState(idSubjectScore[0]?.ticketId);
  const { sampleId } = useParams();
  const [savedAt, setSavedAt] = useState<Date>(new Date());

  const getTimeDifference = (savedTime: Date) => {
    const now = new Date();
    const diffInMs = now.getTime() - savedTime.getTime();
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

    if (diffInMinutes < 60) {
      return `${diffInMinutes}m`;
    } else if (diffInMinutes < 24 * 60) {
      return `${Math.floor(diffInMinutes / 60)}h`;
    } else {
      return `${Math.floor(diffInMinutes / (60 * 24))}d`;
    }
  };

  const [timeDifference, setTimeDifference] = useState<string>(
    getTimeDifference(savedAt)
  );

  const { mutateAsync: skipTicketMutate } = useSkipTicketScore({
    sampleId: sampleId!,
    ticketId: ticketChose,
  });

  const { mutateAsync: putTicketScore, isLoading: isLoadingPutTicketScore } =
    useMutateScore();

  const { data, isLoading, refetch } = useTicketsBySample(
    ticketChose ?? "",
    sampleId ?? ""
  );

  const totalData = data as ITicket;
  const handleTicketClick = (index: number) => {
    setTicketChose(idSubjectScore[index - 1].ticketId);
  };

  const handleSkip = async () => {
    await skipTicketMutate({});
    refetch();
  };

  const handleNextTicket = () => {
    const currentIndex = idSubjectScore.findIndex(
      (entry) => entry.ticketId === ticketChose
    );
    setTicketChose(
      idSubjectScore[
        currentIndex + 1 >= idSubjectScore.length ? 0 : currentIndex + 1
      ].ticketId
    );
  };
  const handlePreviousTicket = () => {
    const currentIndex = idSubjectScore.findIndex(
      (entry) => entry.ticketId === ticketChose
    );
    setTicketChose(
      idSubjectScore[
        currentIndex - 1 >= 0 ? currentIndex - 1 : idSubjectScore.length - 1
      ].ticketId
    );
  };

  const structedCenterData = useMemo(() => {
    const agentName = totalData?.agents?.find((agent) => {
      return agent?.id === totalData?.currTicket?.agentAssignedId;
    });
    const productQuestions = totalData?.currTicket?.ticketScore?.filter(
      (score) => score.title === "productKnowledge"
    );
    const communicationQuestions = totalData?.currTicket?.ticketScore?.filter(
      (score) => score.title === "communication"
    );
    const problemSolvingQuestions = totalData?.currTicket?.ticketScore?.filter(
      (score) => score.title === "problemSolving"
    );
    const techAndProductQuestions = totalData?.currTicket?.ticketScore?.filter(
      (score) => score.title === "technical-knowledge"
    );
    const EscalationQuestions = totalData?.currTicket?.ticketScore?.filter(
      (score) => score.title === "guidelines"
    );

    let array: {
      id?: number;
      metric?: string;
      auto_score?: string;
      adjusted_score?: string;
      reasonForNegativeScore?: string[];
      comment?: string;
      title?: string;
      isGroup?: string;
    }[] = [];

    let totalAutoScore = 0;
    let totalAutoScoreCounter = 0;
    let totalAdjustedScore = 0;
    let totalAdjustedScoreCounter = 0;

    productQuestions?.forEach((score) => {
      array.push({
        id: score.id,
        metric: score.content,
        auto_score: score.autoScore.toString(),
        adjusted_score: score.adjustedScore.toString(),
        comment: score.comment,
        reasonForNegativeScore: score.reasonsForNegativeScore
          ?.join(",")
          ?.replaceAll("_", " ")
          .split(",")
          .filter((d) => d),
      });
      totalAutoScore += score.autoScore;
      totalAutoScoreCounter++;
      totalAdjustedScore += score.adjustedScore;
      totalAdjustedScoreCounter++;
    });

    communicationQuestions?.forEach((score) => {
      array.push({
        id: score.id,
        metric: score.content,
        auto_score: score.autoScore.toString(),
        adjusted_score: score.adjustedScore.toString(),
        comment: score.comment,
        reasonForNegativeScore: score.reasonsForNegativeScore
          ?.join(",")
          ?.replaceAll("_", " ")
          .split(",")
          .filter((d) => d),
      });
      totalAutoScore += score.autoScore;
      totalAutoScoreCounter++;
      totalAdjustedScore += score.adjustedScore;
      totalAdjustedScoreCounter++;
    });

    problemSolvingQuestions?.forEach((score) => {
      array.push({
        id: score.id,
        metric: score.content,
        auto_score: score.autoScore.toString(),
        adjusted_score: score.adjustedScore.toString(),
        comment: score.comment,
        reasonForNegativeScore: score.reasonsForNegativeScore
          ?.join(",")
          ?.replaceAll("_", " ")
          .split(",")
          .filter((d) => d),
      });
      totalAutoScore += score.autoScore;
      totalAutoScoreCounter++;
      totalAdjustedScore += score.adjustedScore;
      totalAdjustedScoreCounter++;
    });

    techAndProductQuestions?.forEach((score) => {
      array.push({
        id: score.id,
        metric: score.content,
        auto_score: score.autoScore.toString(),
        adjusted_score: score.adjustedScore.toString(),
        comment: score.comment,
        reasonForNegativeScore: score.reasonsForNegativeScore
          ?.join(",")
          ?.replaceAll("_", " ")
          .split(",")
          .filter((d) => d),
      });
      totalAutoScore += score.autoScore;
      totalAutoScoreCounter++;
      totalAdjustedScore += score.adjustedScore;
      totalAdjustedScoreCounter++;
    });

    EscalationQuestions?.forEach((score) => {
      array.push({
        id: score.id,
        metric: score.content,
        auto_score: score.autoScore.toString(),
        adjusted_score: score.adjustedScore.toString(),
        comment: score.comment,
        reasonForNegativeScore: score.reasonsForNegativeScore
          ?.join(",")
          ?.replaceAll("_", " ")
          .split(",")
          .filter((d) => d),
      });
      totalAutoScore += score.autoScore;
      totalAutoScoreCounter++;
      totalAdjustedScore += score.adjustedScore;
      totalAdjustedScoreCounter++;
    });

    const totalAdjustedScoreToReturn =
      totalAdjustedScoreCounter > 0
        ? totalAdjustedScore / totalAdjustedScoreCounter
        : 0;
    const totalAutoScoreToReturn =
      totalAutoScoreCounter > 0 ? totalAutoScore / totalAutoScoreCounter : 0;
    array.push({
      metric: "Overall Score",
      auto_score: totalAutoScoreToReturn.toString(),
      adjusted_score: totalAdjustedScoreToReturn.toString(),
    });

    const typeOfquestions =
      totalData?.sample?.sampleConfiguration?.sampleForm?.questions?.length >
        0 &&
      totalData?.sample?.sampleConfiguration?.sampleForm?.questions[0]?.type; // currently all questions are the same type so its enough to take only the first one.

    return {
      mainAgent: agentName?.name ?? "Unknown",
      name: totalData?.currTicket?.id,
      array,
      totalAutoScore: totalAutoScoreToReturn,
      totalAdjustedScore: totalAdjustedScoreToReturn,
      topic: totalData?.currTicket?.topic?.topic,
      typeOfquestions: !!typeOfquestions ? typeOfquestions : "rating",
    };
  }, [totalData]);

  const rightPanel = useMemo(() => {
    const comments = totalData?.currTicket?.comments?.map((comment) => {
      const agent = totalData?.agents?.find((agent) => {
        return agent?.externalId === comment.authorId;
      });

      const agentName = agent?.name ?? "agent";
      const userName = totalData?.tenantUser ?? "user";
      return {
        isUser: comment.agent ? false : true,
        name: comment.agent ? agentName : userName,
        createdAt: new Date(comment.createdAt),
        content: comment.body,
      };
    });
    return comments?.sort(
      (a, b) =>
        new Date(a.createdAt).getDate() - new Date(b.createdAt).getDate()
    );
  }, [totalData]);

  const comments = rightPanel as TPanelComments[];

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeDifference(getTimeDifference(savedAt));
    }, 1000); // Update every second when updated.

    return () => clearInterval(interval);
  }, [savedAt]);

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        setExpandedBounce(true);
      }, 400);
    } else {
      setTimeout(() => {
        setExpandedBounce(false);
      }, 450);
    }
  }, [isExpanded]);

  const handleUpdateAdjustedScore = async (id: number, value: string) => {
    await putTicketScore({
      adjustedScore: +value,
      scoreId: id,
    });
    setSavedAt(new Date());
    refetch();
  };

  const handleUpdateComment = async (id: number, value: string) => {
    await putTicketScore({
      comment: value,
      scoreId: id,
    });
    setSavedAt(new Date());
    refetch();
  };

  const dynamicBadgeOptions = useMemo(() => {
    if (structedCenterData?.typeOfquestions === "binary") {
      return badgeBinary;
    }
    return badgeOptions;
  }, [structedCenterData]);

  const handleUpdateReason = async (scoreId: number, value: string[]) => {
    await putTicketScore({
      reasonsForNegativeScore: value
        .join(",")
        .replaceAll(" ", "_")
        .toLowerCase()
        .split(",")
        .filter((d) => d),
      scoreId: scoreId,
    });
    setSavedAt(new Date());
    refetch();
  };

  // const reasonEntry = useMemo(() => {
  //   const handleUpdateReason = async (rowIndex: number, value: string[]) => {
  //     await putTicketScore({
  //       reasonsForNegativeScore: value
  //         .join(",")
  //         .replaceAll(" ", "_")
  //         .toLowerCase()
  //         .split(","),
  //       scoreId: rowIndex,
  //     });
  //     setSavedAt(new Date());
  //     refetch();
  //   };

  //   return {
  //     value: "reasonForNegativeScore",
  //     label: "Reason",
  //     type: EColumnTypes.CHOOSE,
  //     selectOptions: {
  //       optionsChooseFilter: reasonsAnswers,
  //       handleChangeChooseFilter: handleUpdateReason,
  //       multiple: true,
  //     },
  //   };
  // }, [putTicketScore, refetch]);

  return (
    <div>
      <div className={styles.panelContainer}>
        {!isLoading && idSubjectScore?.length ? (
          <>
            <div
              className={cx(styles.leftContainer)}
              style={{
                width: isExpanded ? "460px" : "93px",
              }}
            >
              <div className={styles.header}>
                <div
                  className={styles.text}
                  style={{
                    opacity: isExpanded ? "1" : "0",
                  }}
                >
                  {isExpandedBounced && (
                    <>
                      <div className={styles.title}>
                        {"Tickets to Review"}
                        <span>{idSubjectScore.length}</span>
                      </div>
                    </>
                  )}
                </div>
                <button
                  className={styles.expandBtn}
                  onClick={() => setExpanded((prev) => !prev)}
                >
                  {isExpanded ? (
                    <MdKeyboardDoubleArrowLeft />
                  ) : (
                    <MdKeyboardDoubleArrowRight />
                  )}
                </button>
              </div>
              <div className={styles.commonQuestionsContainer}>
                {idSubjectScore &&
                  idSubjectScore.map((ticket: any, index: number) => {
                    return (
                      <SampleTicket
                        onClick={(index) => {
                          handleTicketClick(index);
                        }}
                        isExpanded={isExpandedBounced}
                        isExapndedImmediat={isExpanded}
                        index={index + 1}
                        key={ticket.ticketId}
                        state={ticket}
                        active={ticket.ticketId === ticketChose}
                      />
                    );
                  })}
              </div>
            </div>
            <div
              className={cx(styles.rightContainer, {
                [styles.shrinked]: isExpanded,
              })}
            >
              <div className={styles.header}>
                <div>
                  Ticket #
                  {totalData?.currTicket?.displayId ??
                    ticketChose.split("::")[1]}
                  <GeistBadge>{structedCenterData.topic}</GeistBadge>
                </div>
                <div className={styles.headerRight}>
                  <div>
                    <label>Auto-Score</label>
                    <GeistBadge mode={EBadgeMode.GREEN}>
                      {structedCenterData.totalAutoScore}
                    </GeistBadge>
                  </div>
                  <div>
                    <label>Adjusted Score</label>
                    <GeistBadge mode={EBadgeMode.PURPLE}>
                      {structedCenterData.totalAdjustedScore}
                    </GeistBadge>
                    <div>
                      <button
                        className={styles.navigationBtn}
                        onClick={() => {
                          handlePreviousTicket();
                        }}
                      >
                        <ArrowLeft />
                      </button>
                      <button
                        className={styles.navigationBtn}
                        onClick={() => {
                          handleNextTicket();
                        }}
                      >
                        <ArrowRight />
                      </button>
                    </div>
                  </div>

                  <Tooltip
                    type="dark"
                    text={
                      "it will remove the ticket from the sample, and Quack won't learn from it"
                    }
                    font={0.75}
                    padding={0.75}
                  >
                    <Button
                      onClick={handleSkip}
                      type="default"
                      placeholder=""
                      onPointerEnterCapture={() => {}}
                      onPointerLeaveCapture={() => {}}
                    >
                      Skip ticket
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <div className={styles.rightBody}>
                <div className={cx(styles.ranking)}>
                  <div className={styles.details}>
                    <div>
                      <label>Main Agent</label>
                      <b>
                        {structedCenterData.mainAgent?.replace(/\b\w/g, (c) =>
                          c.toUpperCase()
                        )}
                      </b>
                    </div>
                    <div>
                      <b>
                        {!isLoadingPutTicketScore
                          ? timeDifference === "0m"
                            ? "Last saved: Just now"
                            : "Last saved: " + timeDifference + " ago"
                          : "Saving..."}
                      </b>
                    </div>
                  </div>
                  <div className={styles.analyze}>
                    <label className={styles.label}>Analyze</label>

                    {structedCenterData?.typeOfquestions === "binary" ? (
                      <>
                        {(
                          structedCenterData.array.sort((a: any, b: any) => {
                            const order = ["u", "s", "e", "r", "o"];
                            return (
                              order.indexOf(a.metric[0].toLowerCase()) -
                              order.indexOf(b.metric[0].toLowerCase())
                            );
                          }) as any
                        ).map((x: any) => {
                          if (x.metric === "Overall Score") {
                            return (
                              <div style={{ display: "flex", gap: "16px" }}>
                                Summary -<div>Auto score: {x.auto_score}</div>
                                <div>Adjusted score: {x.adjusted_score}</div>
                              </div>
                            );
                          }

                          return (
                            <div
                              key={x.id}
                              style={{
                                display: "flex",
                                gap: "8px",
                                flexDirection: "column",
                                background: "#1e1e1e",
                                borderRadius: "12px",
                                padding: "16px",
                              }}
                            >
                              <label style={{ textTransform: "capitalize" }}>
                                {x.metric.toLowerCase()}
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <label>Auto score: </label>
                                  <GeistBadge
                                    mode={
                                      badgeBinary[
                                        x.auto_score as keyof typeof badgeBinary
                                      ]
                                    }
                                  >
                                    {x.auto_score}
                                  </GeistBadge>
                                </div>
                                <div>
                                  <label>Adjusted score: </label>
                                  <GeistSelect
                                    options={
                                      [0, 1].map((answer) => {
                                        return {
                                          value: answer.toString(),
                                          label: answer.toString(),
                                        };
                                      }) || []
                                    }
                                    handleChange={(v) => {
                                      handleUpdateAdjustedScore(
                                        x.id,
                                        v as string
                                      );
                                    }}
                                    initialValue={{
                                      value: x.adjusted_score,
                                      label: x.adjusted_score,
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className={styles.reasonsContainer}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label>Reasons:</label>
                                <GeistSelect
                                  style={{ width: "100% !important" }}
                                  multiple={true}
                                  options={
                                    reasonsAnswers
                                      .find((r) => r.question === x.metric)
                                      ?.answers.map((answer) => {
                                        return {
                                          value: answer.title,
                                          label: answer.title,
                                        };
                                      }) || []
                                  }
                                  handleChange={(v) => {
                                    handleUpdateReason(x.id, v as string[]);
                                  }}
                                  initialValue={{
                                    label: "",
                                    value: x.reasonForNegativeScore.map(
                                      (r: string) => {
                                        return r.toUpperCase();
                                      }
                                    ),
                                  }}
                                />
                              </div>
                              <div className={styles.commentContainer}>
                                <label>Comments:</label>
                                <TextField
                                  key={x.comment}
                                  index={x.id}
                                  handleBlur={(_, text: string) => {
                                    handleUpdateComment(x.id, text);
                                  }}
                                  handleFocus={() => {}}
                                  value={x.comment}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <GDashboardTable
                        disableSelected
                        firstColumnWidth="25%"
                        columns={[
                          {
                            value: "metric",
                            label: "Metric",
                            type: EColumnTypes.DEFAULT,
                          },
                          {
                            value: "auto_score",
                            label: "Auto-Score",
                            type: EColumnTypes.BADGE,
                            badgeOptions: dynamicBadgeOptions,
                            forceWidth: "10%",
                          },
                          {
                            value: "adjusted_score",
                            label: "Adjusted-Score",
                            type: EColumnTypes.RATING,
                            ratingOptions: {
                              type:
                                structedCenterData?.typeOfquestions === "binary"
                                  ? "binary"
                                  : "multiple",
                              handleChangeRatingFilter:
                                handleUpdateAdjustedScore,
                            },
                            handleFocus: () => {},
                            handleBlur: handleUpdateAdjustedScore,
                          },
                          // ...(structedCenterData?.typeOfquestions === "binary"
                          //   ? [reasonEntry]
                          //   : []),
                          {
                            value: "comment",
                            label: "Comment",
                            type: EColumnTypes.TEXT,
                            badgeOptions: dynamicBadgeOptions,
                            handleFocus: () => {},
                            handleBlur: handleUpdateComment,
                          },
                        ]}
                        dataArray={
                          structedCenterData.array.sort(
                            (a: any, b: any) => a?.id - b?.id
                          ) as any
                        }
                        selectedTopic={20}
                        handleRowClick={() => {}}
                        isGrouping
                        isFooter
                        tablesWidth="150%"
                      />
                    )}
                  </div>
                </div>
                <div className={styles.chat}>
                  {comments?.map((comment, index) => {
                    return (
                      <div
                        className={cx(styles.message, {
                          [styles.agentMessage]: !comment.isUser,
                        })}
                        key={index}
                      >
                        <div className={styles.messageHeader}>
                          <b>{comment.name}</b>
                          <label>
                            {dayjs(comment.createdAt).format("DD/MM/YYYY")}
                          </label>
                        </div>
                        {comment.content
                          .replace(/<\/?[^>]+(>|$)/g, "")
                          .replace(/&#39;/g, "'")
                          .replace(/&#128075;/g, "👋")
                          .replace(/&quot;/g, '"')
                          .replace(/&amp;/g, "&")
                          .replace(/&lt;/g, "<")
                          .replace(/&gt;/g, ">")
                          .replace(/&nbsp;/g, " ")
                          .replace(/&copy;/g, "©")
                          .replace(/&reg;/g, "®")
                          .replace(/&euro;/g, "€")
                          .replace(/&pound;/g, "£")
                          .replace(/&yen;/g, "¥")
                          .replace(/&cent;/g, "¢")
                          .replace(/&sect;/g, "§")
                          .replace(/&deg;/g, "°")
                          .replace(/&plusmn;/g, "±")
                          .replace(/&times;/g, "×")
                          .replace(/&divide;/g, "÷")
                          .replace(/&trade;/g, "™")
                          .replace(/&hellip;/g, "…")
                          .replace(/&mdash;/g, "—")
                          .replace(/&ndash;/g, "–")
                          .replace(/&lsquo;/g, "‘")
                          .replace(/&rsquo;/g, "’")
                          .replace(/&ldquo;/g, "“")
                          .replace(/&rdquo;/g, "”")
                          .replace(/&bull;/g, "•")}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.skeleton} />
        )}
      </div>
    </div>
  );
};

const TextField = ({
  index,
  value,
  handleBlur,
  handleFocus,
  disabled = false,
}: {
  index: number;
  value: string;
  handleBlur: (index: number, value: string) => void;
  handleFocus: (index: number) => void;
  disabled?: boolean;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [v, setValue] = useState(value);
  const [height, setHeight] = useState("unset");

  useEffect(() => {
    setValue(value);
    setTimeout(() => {
      if (textAreaRef.current) {
        setHeight(`${textAreaRef.current.scrollHeight}px`);
      }
    }, 300);
  }, [value]);

  return (
    <Textarea
      ref={textAreaRef}
      onChange={(e) => {
        const inputValue = e.target.value;
        setValue(inputValue);
        e.target.style.height = "auto";
        e.target.style.height = `${e.target.scrollHeight}px`;
      }}
      onBlur={(e) => {
        handleBlur(index, e.target.value);
      }}
      onFocus={(e) => {
        handleFocus(index);
        e.target.select();
      }}
      value={v}
      disabled={disabled}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      style={{ resize: "none", overflow: "hidden", height: height }}
    />
  );
};
