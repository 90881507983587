import { useAuthorizedMutation, useAuthorizedQuery } from "..";
import { params } from "../../params";

export type OpportunityType = {
  resolution: "resolution";
  knowledge: "knowledge";
  quality: "quality";
};

export enum OpportunityStatus {
  HIDDEN = "hidden",
  SOLVED = "solved",
  TODO = "todo",
  VERIFY = "verify",
}

export interface Opportunity {
  id: number;
  label: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  type: OpportunityType;
  status: OpportunityStatus;
  transactionId: number;
  transaction: {
    id: number;
    tenantId: string;
    userContent: string;
    agentContent: string;
    createdAt: Date;
    updatedAt: Date;
    tickets: {
      id: string;
    }[];
    commonTransactionEval: {
      id: number;
      transactionId: number;
      tenantId: string;
      createdAt: Date;
      explanation: string;
      updatedAt: Date;
      isCovered: boolean;
      relatedArticlesIds: string[];
    };
  };
}

export const useOpportunities = ({ enabled }: { enabled: boolean }) => {
  return useAuthorizedQuery({
    queryKey: `useGetOpportunities`,
    url: `${params.API_URL}/platform/opportunity?statuses[]=hidden&statuses[]=todo`,
    method: "GET",
    enabled,
  });
};

export const useResolvedOpportunities = ({ enabled }: { enabled: boolean }) => {
  return useAuthorizedQuery({
    queryKey: `useGetResolvedOpportunities`,
    url: `${params.API_URL}/platform/opportunity?statuses[]=solved&statuses[]=verify`,
    method: "GET",
    enabled,
  });
};

export const useChangeStatusToVerify = ({
  opportunityId,
}: {
  opportunityId: number;
}) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/opportunity/${opportunityId}`,
    method: "PUT",
  });
};

export const useChangeStatusToHidden = ({
  opportunityId,
}: {
  opportunityId: number;
}) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/opportunity/${opportunityId}`,
    method: "PUT",
  });
};
