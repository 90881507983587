import React from "react";
import styles from "./styles.module.scss";
import { IoMdTrash } from "react-icons/io";
import cx from "classnames";
import { useDeleteMember } from "../../../accountSetup/api/useMembers";
import { Member } from "../../../accountSetup/types";
import { createToast } from "vercel-toast";
import { Badge, Tooltip } from "@radix-ui/themes";
import { Tool } from "@geist-ui/icons";

export const Card = ({
  member,
  refetch,
}: {
  member: Member;
  refetch: () => void;
}) => {
  const { mutate: deleteUserMutate, isLoading } = useDeleteMember(member.email);
  return (
    <div className={cx(styles.container)}>
      <div>
        {member.email}
        <Tooltip content="To change the permission -> please reach out support">
          <Badge color={member.isManager ? "blue" : "tomato"}>
            {member.isManager ? "Platform + Copilot" : "Just Copilot"}
          </Badge>
        </Tooltip>
      </div>
      {isLoading ? (
        <div>loading..</div>
      ) : (
        <button
          onClick={() => {
            deleteUserMutate(
              {},
              {
                onSuccess: () => {
                  createToast(`Member ${member.email} deleted`, {
                    type: "success",
                    timeout: 3000,
                  });
                  refetch();
                },
              }
            );
          }}
        >
          <IoMdTrash />
        </button>
      )}
    </div>
  );
};
