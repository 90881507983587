import React, { Fragment, useMemo } from "react";
import { Tabs } from "@geist-ui/core";
import { useParams } from "react-router-dom";

import { InfoCard } from "../../components/infoCard";
import { GDashboardTable } from "../../components/geistTableDashboard";
import {
  EColumnTypes,
  ETicketScoreTitle,
} from "../../components/geistTableDashboard/index.types";

import { ExpandableListLayout } from "./components";
import { badgeBinary, badgeOptions } from "./constants";
import styles from "./styles.module.scss";
import { useSample } from "../../api/useSamples";
import dayjs from "dayjs";

interface TSample {
  id: number;
  name: string;
  tenantId: string;
  createdAt: string;
  dateFrom: string;
  dateTo: string;
  sampleConfiguration: {
    sampleForm: { questions: { type: string; question: string }[] };
  };
  tickets: {
    id: number;
    tenantId: string;
    sampleId: number;
    ticketId: string;
    ticket: {
      id: string;
      displayId: string | null;
      ticketScore: {
        id: number;
        title: string;
        key: string;
        content: string;
        tenantId: string;
        ticketId: string;
        createdAt: string;
        updateAt: string;
        autoScore: number;
        adjustedScore: number;
        autoScoreExplanation: string;
      }[];
      solvedAt: string | null;
      agentAssignedId: number | null;
      subject: string;
      topic?: {
        id: number;
        name: string;
        topic: string;
        tenant: string;
        enabledPolicies: [];
      };
      comments: {
        id: string;
        body: string;
        source: string;
        authorId: string;
        email: string;
        agent: boolean;
        createdAt: Date;
      };
    };
  }[];
  agents: {
    id: number;
    name: string;
    externalId: string;
  }[];
}

export const SamplingById = () => {
  const { sampleId } = useParams<{ sampleId: string }>();

  const { data, isLoading } = useSample(sampleId!);
  const structedData = useMemo(() => {
    const item = data as TSample;
    let agents: Record<
      string,
      {
        ticketCount: number;
        scoreCount: number;
        autoScoreTotal: number;
        adjustedScoreTotal: number;
      }
    > = {};
    let topics: Record<
      string,
      {
        name: string;
        ticketCount: number;
        agentArray: string[];
        knowledgeScore: number;
        knowledgeScoreCount: number;
        comScore: number;
        comScoreCount: number;
        problemSolving: number;
        problemSolvingCount: number;
        techAndProduct: number;
        techAndProductCount: number;
        escalatedProcess: number;
        escalatedProcessCount: number;
      }
    > = {};

    const length = item?.tickets?.length;
    item?.tickets?.forEach((currTicket) => {
      let assigneeId = currTicket.ticket?.agentAssignedId ?? "Unknown";
      let topic = currTicket.ticket?.topic;
      let topicId = topic?.id ?? "Unknown";
      const topicName = topic?.name ?? topic?.topic ?? "Unknown";

      if (!agents[assigneeId]) {
        agents[assigneeId] = {
          ticketCount: 0,
          scoreCount: 0,
          autoScoreTotal: 0,
          adjustedScoreTotal: 0,
        };
      }
      if (!topics[topicId]) {
        topics[topicId] = {
          name: topicName,
          ticketCount: 0,
          agentArray: [],
          knowledgeScore: 0,
          knowledgeScoreCount: 0,
          comScore: 0,
          comScoreCount: 0,
          problemSolving: 0,
          problemSolvingCount: 0,
          techAndProduct: 0,
          techAndProductCount: 0,
          escalatedProcess: 0,
          escalatedProcessCount: 0,
        };
      }

      agents[assigneeId].ticketCount += 1;
      topics[topicId].ticketCount += 1;

      currTicket.ticket.ticketScore.forEach((scoreDetails) => {
        agents[assigneeId] = {
          ticketCount: agents[assigneeId].ticketCount,
          scoreCount: agents[assigneeId].scoreCount + 1,
          autoScoreTotal:
            agents[assigneeId].autoScoreTotal + (scoreDetails?.autoScore || 0),
          adjustedScoreTotal:
            agents[assigneeId].adjustedScoreTotal +
            (scoreDetails?.adjustedScore || 0),
        };

        if (scoreDetails.title === ETicketScoreTitle.KNOWLEDGE_SCORE) {
          topics[topicId].knowledgeScore += scoreDetails.autoScore ?? 0;
          topics[topicId].knowledgeScoreCount += 1;
        }
        if (scoreDetails.title === ETicketScoreTitle.COM_SCORE) {
          topics[topicId].comScore =
            topics[topicId].comScore + (scoreDetails.autoScore || 0);
          topics[topicId].comScoreCount += 1;
        }
        if (scoreDetails.title === ETicketScoreTitle.PROBLEM_SOLVING_SCORE) {
          topics[topicId].problemSolving += scoreDetails.autoScore ?? 0;
          topics[topicId].problemSolvingCount += 1;
        }
        if (scoreDetails.title === ETicketScoreTitle.TECH_AND_PRODUCT_SCORE) {
          topics[topicId].techAndProduct += scoreDetails.autoScore ?? 0;
          topics[topicId].techAndProductCount += 1;
        }
        if (scoreDetails.title === ETicketScoreTitle.ESCALATION_PROCESS_SCORE) {
          topics[topicId].escalatedProcess += scoreDetails.autoScore ?? 0;
          topics[topicId].escalatedProcessCount += 1;
        }
        if (!topics[topicId].agentArray.includes(assigneeId.toString())) {
          topics[topicId].agentArray.push(assigneeId.toString());
        }
      });
    });
    const agentDataTable = Object.entries(agents).map(([agentId, agent]) => {
      const autoScore =
        agent.scoreCount > 0
          ? (agent.autoScoreTotal / agent.scoreCount).toFixed(1)
          : 0;
      const adjustedScore =
        agent.scoreCount > 0
          ? (agent.adjustedScoreTotal / agent.scoreCount).toFixed(1)
          : 0;

      const agentName =
        item?.agents.find((agent) => agent.id.toString() === agentId)?.name ??
        "Unknown";

      return {
        agent: agentName,
        tickets: agent.ticketCount.toString(),
        auto_score: autoScore.toString(),
        adjusted_score: adjustedScore.toString(),
      };
    });

    const topicDataTable = Object.entries(topics).map(
      ([topicId, currTopic]) => {
        const knowledge_score =
          currTopic?.knowledgeScoreCount > 0
            ? (
                currTopic.knowledgeScore / currTopic.knowledgeScoreCount
              ).toFixed(1)
            : 0;
        const com_score =
          currTopic?.comScoreCount > 0
            ? (currTopic.comScore / currTopic.comScoreCount).toFixed(1)
            : 0;
        const problem_solving_score =
          currTopic.problemSolvingCount > 0
            ? (
                currTopic.problemSolving / currTopic.problemSolvingCount
              ).toFixed(1)
            : 0;
        const tech_and_product_score =
          currTopic.techAndProductCount > 0
            ? (
                currTopic.techAndProduct / currTopic.techAndProductCount
              ).toFixed(1)
            : 0;
        const escalation_process_score =
          currTopic.escalatedProcessCount > 0
            ? (
                currTopic.escalatedProcess / currTopic.escalatedProcessCount
              ).toFixed(1)
            : 0;
        return {
          topic: topics[topicId].name,
          tickets: `${currTopic.ticketCount} Tickets`,
          agents: `${topics[topicId].agentArray.length}`,
          [ETicketScoreTitle.KNOWLEDGE_SCORE]: knowledge_score.toString(),
          [ETicketScoreTitle.COM_SCORE]: com_score.toString(),
          [ETicketScoreTitle.PROBLEM_SOLVING_SCORE]:
            problem_solving_score.toString(),
          [ETicketScoreTitle.TECH_AND_PRODUCT_SCORE]:
            tech_and_product_score.toString(),
          [ETicketScoreTitle.ESCALATION_PROCESS_SCORE]:
            escalation_process_score.toString(),
        };
      }
    );
    const idSubjectScore = item?.tickets?.map((ticket) => {
      return {
        ticketId: ticket.ticket.id,
        displayId: ticket.ticket.displayId ?? ticket.ticket.id.split("::")[1],
        subject:
          ticket.ticket.subject ?? ticket.ticket.topic?.topic ?? "Unknown",
        score: ticket.ticket.ticketScore[0]?.autoScore ?? 0,
      };
    });
    return {
      name: item?.name,
      dateFrom: item?.dateFrom ?? "",
      dateTo: item?.dateTo ?? "",
      agents,
      length,
      createdAt: item?.createdAt,
      agentDataTable,
      topicDataTable,
      idSubjectScore,
      questionsType:
        item?.sampleConfiguration?.sampleForm?.questions?.length > 0
          ? item.sampleConfiguration.sampleForm.questions[0].type
          : "rating",
    };
  }, [data]);

  const restScoreColumns = useMemo(() => {
    const dynamicBadgeOptions =
      structedData.questionsType === "rating" ? badgeOptions : badgeBinary;
    if (!data) return [];
    if (data.tenantId === "yotpo") {
      return [
        {
          label: "Avg. Tech And Product score",
          value: ETicketScoreTitle.TECH_AND_PRODUCT_SCORE,
          type: EColumnTypes.BADGE,
          badgeOptions: dynamicBadgeOptions,
        },
        {
          label: "Avg. Escalation Process score",
          value: ETicketScoreTitle.ESCALATION_PROCESS_SCORE,
          type: EColumnTypes.BADGE,
          badgeOptions: dynamicBadgeOptions,
        },
      ];
    }
    return [
      {
        label: "Avg. Knowledge score",
        value: ETicketScoreTitle.KNOWLEDGE_SCORE,
        type: EColumnTypes.BADGE,
        badgeOptions: dynamicBadgeOptions,
      },
      {
        label: "Avg. Communication score",
        value: ETicketScoreTitle.COM_SCORE,
        type: EColumnTypes.BADGE,
        badgeOptions: dynamicBadgeOptions,
      },
      {
        label: "Avg. Problem-Solving score",
        value: ETicketScoreTitle.PROBLEM_SOLVING_SCORE,
        type: EColumnTypes.BADGE,
        badgeOptions: dynamicBadgeOptions,
      },
    ];
  }, [data, structedData]);

  const dynamicBadgeOptions = useMemo(() => {
    if (!data) return badgeOptions;
    const dataType = data as TSample;
    if (
      dataType.sampleConfiguration?.sampleForm?.questions?.length > 0 &&
      dataType.sampleConfiguration?.sampleForm?.questions[0]?.type === "rating"
    ) {
      return badgeOptions;
    }
    return badgeBinary;
  }, [data]);

  return !isLoading && structedData?.length ? (
    <div className={styles.sampleContainer}>
      <div className={styles.header}>
        <h2>{structedData.name}</h2>
        <div>{dayjs(structedData.createdAt).format("DD/MM/YYYY")}</div>
      </div>
      <Tabs initialValue="overview" className={"samplingTabs"}>
        <Tabs.Item label="Overview" value={"overview"}>
          <div className={styles.container}>
            <div className={styles.overviewHeader}>
              <InfoCard
                loading={false}
                title="Sampled tickets"
                tooltipText="Number of tickets that were sampled"
                percentage={undefined}
                lessIsGood
              >
                {structedData.length}
              </InfoCard>
              <InfoCard
                loading={false}
                title="Date Range"
                tooltipText="Range of dates that were sampled"
                percentage={undefined}
                lessIsGood
              >
                {`${dayjs(structedData?.dateFrom).format(
                  "DD/MM/YYYY"
                )} -> ${dayjs(structedData?.dateTo).format("DD/MM/YYYY")}`}
              </InfoCard>
              <InfoCard
                loading={false}
                title="Type"
                tooltipText="Type of the sample, like Date Range, Random, etc."
                percentage={undefined}
                lessIsGood
              >
                Date Selection
              </InfoCard>
            </div>
            <GDashboardTable
              title="Agents Performence"
              dataArray={structedData?.agentDataTable}
              columns={[
                {
                  label: "Agent",
                  value: "agent",
                  type: EColumnTypes.DEFAULT,
                },
                {
                  label: "Avg. auto-Score",
                  value: "auto_score",
                  type: EColumnTypes.BADGE,
                  badgeOptions: dynamicBadgeOptions,
                },
                {
                  label: "Avg. Adjusted Score",
                  value: "adjusted_score",
                  type: EColumnTypes.BADGE,
                  badgeOptions: dynamicBadgeOptions,
                },
              ]}
              selectedTopic={20}
              handleRowClick={() => {}}
            />
            <GDashboardTable
              title="Topic Analysis"
              dataArray={structedData.topicDataTable}
              columns={[
                {
                  label: "Topic",
                  value: "topic",
                  type: EColumnTypes.DEFAULT,
                  badgeOptions: dynamicBadgeOptions,
                },
                {
                  label: "Sampled Tickets",
                  value: "tickets",
                  type: EColumnTypes.DEFAULT,
                  badgeOptions: dynamicBadgeOptions,
                },
                {
                  label: "Agents",
                  value: "agents",
                  type: EColumnTypes.DEFAULT,
                  badgeOptions: dynamicBadgeOptions,
                },
                ...restScoreColumns,
              ]}
              selectedTopic={20}
              handleRowClick={() => {}}
            />
          </div>
        </Tabs.Item>
        <Tabs.Item label="Samples" value={"samples"}>
          <ExpandableListLayout idSubjectScore={structedData.idSubjectScore} />
        </Tabs.Item>
      </Tabs>
    </div>
  ) : (
    <Fragment />
  );
};
