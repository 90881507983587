import React, { useState } from "react";
import {
  Opportunity,
  OpportunityStatus,
  useChangeStatusToHidden,
} from "../../api/useOpportunities";
import Tooltip from "@geist-ui/core/esm/tooltip";
import { Button } from "@radix-ui/themes";
import { Info } from "@geist-ui/icons";
import { InfoIcon } from "lucide-react";
import { useAuth0 } from "@auth0/auth0-react";
export const OpportunityComponent = ({
  opportunity,
  refetch,
}: {
  opportunity: Opportunity;
  refetch: () => void;
}) => {
  const { user } = useAuth0();
  const [isHover, setHover] = useState(false);

  const { mutateAsync: changeStatusToHidden, isLoading: loadingHidden } =
    useChangeStatusToHidden({
      opportunityId: +opportunity.id,
    });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "400px",
        margin: "8px 0",
        padding: "8px",
        fontSize: "13px",
        transition: "background-color 0.3s",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <Tooltip
          text={
            <div>
              <div>
                Related tickets: {opportunity.transaction.tickets.length}
              </div>
              <hr />
              <div>
                {opportunity.transaction.commonTransactionEval.explanation}
              </div>
            </div>
          }
        >
          <InfoIcon width={"12px"} height={"12px"} />
        </Tooltip>
        {opportunity.label}
      </span>
      {isHover && (
        <Button
          color="gray"
          variant="ghost"
          onClick={() =>
            changeStatusToHidden({
              status: OpportunityStatus.HIDDEN,
              userEmail: user?.email,
            }).then(() => {
              refetch();
            })
          }
        >
          Ignore
        </Button>
      )}
    </div>
  );
};
