import Select from "@geist-ui/core/esm/select";
import styles from "./styles.module.scss";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import { Button } from "@radix-ui/themes";

const validateEmail = (email: string): boolean => {
  const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexPattern.test(email);
};

export const AddMember = ({
  onClick,
  isLoading,
}: {
  onClick: Function;
  isLoading: boolean;
}) => {
  const [email, setEmail] = useState("");
  const [permission, setPermission] = useState("manager");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const handlePermissionChange = (value: string) => setPermission(value);

  const labels = {
    invite: "Invite member",
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && validateEmail(email)) {
      onClick(email, permission);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setEmail("");
    }
  }, [isLoading]);

  return (
    <div className={cx(styles.add_member_container)}>
      <input
        type="text"
        id="member_email"
        name="member_email"
        placeholder="Add member by email"
        value={email}
        autoFocus
        onChange={handleEmailChange}
        onKeyPress={handleKeyPress}
      />
      <Select
        className="ml-2 p-2 border rounded"
        id="permissions"
        value={permission}
        onChange={(value) => handlePermissionChange(value as string)}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <Select.Option value="not_manager">Copilot</Select.Option>
        <Select.Option value="manager">Platform + Copilot</Select.Option>
      </Select>
      <Button
        color="gray"
        highContrast
        disabled={isLoading || !validateEmail(email)}
        onClick={() => onClick(email, permission)}
        loading={isLoading}
      >
        {isLoading ? "loading.." : labels.invite}
      </Button>
    </div>
  );
};
